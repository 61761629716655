import { Box, Card, CardContent, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import styles from "app/cards/styles";
import InlineLabeledText from "app/components/InlineLabeledText";
import LoaderButton from "app/components/LoaderButton";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AccountsTotals = ({title, method}: any) => {
    const {partner}   = useLoggedInRoute();
    const {getTotals} = useApi();
    const navigate    = useNavigate();
    
    const [query, setQuery] = useState({
        start_date: moment().format('YYYY-MM-DD'),
        end_date  : moment().format('YYYY-MM-DD')
    })
    const [startDate, setStartDate] = useState(query.start_date);
    const [endDate, setEndDate]     = useState(query.end_date);
    const [loading, setLoading]     = useState(false);

    const [gross, setGross] = useState(0);
    const [net, setNet] = useState(0);

    const [data, setData] = useState<any>({});

    const refresh = () => {
        setLoading(true);
        getTotals(method, query)
            .then(response => setData(response))
            .finally(() => setLoading(false));
    }

    const computeTotal = (key: string) => (
        (total: number, item: any) => (
            total +
            (item.cash_in?.[key] || 0) +
            (item.cash_out?.[key] || 0)
        )
    )
    const computeTotals = () => {
        setGross(Object.values(data as object).reduce(computeTotal('gross'), 0))
        setNet(Object.values(data as object ).reduce(computeTotal('net'), 0))
    }

    useEffect(() => partner?.access_token && refresh(), [query, partner?.access_token])
    useEffect(computeTotals, [data])

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container
                    spacing        = {2}
                    justifyContent = "space-between"
                    alignItems     = "center"
                >
                    <Grid item xs={12} md marginBottom={4}>
                        <Box mb={1}>
                            <Typography
                                sx            = {styles.title}
                                textTransform = "uppercase"
                                display       = "inline"
                                variant       = "h6"
                                fontWeight    = "bold"
                            >
                                {title}
                            </Typography>
                            <LoaderButton
                                loading  = {loading}
                                disabled = {loading}
                                display  = "inline"
                                margin   = "0 1rem"
                            >
                                Refresh
                            </LoaderButton>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item>
                                <InlineLabeledText
                                    label      = "Total Gross"
                                    value      = {amountFormat(gross)}
                                    labelProps = {{variant: 'h6', fontWeight: 'normal'}}
                                    valueProps = {{variant: 'h6'}}
                                />
                            </Grid>
                            <Grid item>
                                <InlineLabeledText
                                    label      = "Total Net"
                                    value      = {amountFormat(net)}
                                    labelProps = {{variant: 'h6', fontWeight: 'normal'}}
                                    valueProps = {{variant: 'h6'}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md marginBottom={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md>
                                <StartDate
                                    value    = {startDate}
                                    onChange = {(e: any) => setStartDate(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md>
                                <EndDate 
                                    value    = {endDate}
                                    onChange = {(e: any) => setEndDate(e.target.value)}
                                />
                            </Grid>
                            <Grid xs={12} md lg={2} item alignContent="center">
                                <LoaderButton fullWidth
                                    loading  = {loading}
                                    disabled = {loading}
                                    onClick  = {() => setQuery({start_date: startDate, end_date: endDate})}
                                >
                                    Apply
                                </LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tablePaper}>
                    <Table size="small" stickyHeader sx={styles.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="300px">Account</TableCell>
                                {'card' == method? <>
                                    <TableCell>Gross</TableCell>
                                    <TableCell>Net</TableCell>
                                </>: <>
                                    <TableCell>Cash-In (Gross)</TableCell>
                                    <TableCell>Cash-In (Net)</TableCell>
                                    <TableCell>Cash-Out (Gross)</TableCell>
                                    <TableCell>Cash-Out (Net)</TableCell>
                                    <TableCell>Cash-Out (Wallet)</TableCell>
                                </>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(data).map(account => (
                                <TableRow>
                                    <TableCell >
                                        <Link
                                            component = "button"
                                            underline = "hover"
                                            onClick   = {() => navigate(`../${account}`)}
                                        >
                                            {account}
                                        </Link>
                                    </TableCell>
                                    {'card' == method? <>
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_in?.gross)}
                                            date   = {data[account].cash_in?.last_transaction?.date}
                                        />
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_in?.net)}
                                            date   = {data[account].cash_in?.last_transaction?.date}
                                        />
                                    </>: <>
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_in?.gross)}
                                            date   = {data[account].cash_in?.last_transaction?.date}
                                        />
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_in?.net)}
                                            date   = {data[account].cash_in?.last_transaction?.date}
                                        />
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_out?.gross)}
                                            date   = {data[account].cash_out?.last_transaction?.date}
                                        />
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_out?.net)}
                                            date   = {data[account].cash_out?.last_transaction?.date}
                                        />
                                        <TotalCell
                                            amount = {amountFormat(data[account].cash_out?.balance)}
                                            date   = {data[account].cash_out?.last_transaction?.date}
                                        />
                                    </>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

const amountFormat = (amount: number) => (amount || 0).toLocaleString('en-US', {style: 'currency', currency: 'PHP'})

const DateField    = (props: any) => <TextField fullWidth variant="outlined" type="date" InputLabelProps={{shrink: true}} {...props}/>
const StartDate    = (props: any) => <DateField label = "Start Date" {...props}/>
const EndDate      = (props: any) => <DateField label = "End Date" {...props}/>

const TableHelper  = ({children}: any) => (
    <Typography
        variant    = "subtitle2"
        color      = "GrayText"
        fontStyle  = "italic"
        fontWeight = "normal"
        fontSize   = {12}
    >
        {children}
    </Typography>
)
const TotalCell = ({date, amount}: any) => (
    <TableCell>
        {amountFormat(amount)}
        {date &&
            <TableHelper>({
                moment(date).format('M/D/YY HH:mm:ss')
            })</TableHelper>
        }
    </TableCell>
)

export default AccountsTotals;