import { Grid } from "@mui/material";
import TerminalGraph from "app/cards/TerminalGraph";
import TerminalSettings from "app/cards/TerminalSettings";

const Terminals = () => {
    const cards: any[] = [
        TerminalGraph,
        () => <TerminalSettings terminal_group="qrph"/>,
        () => <TerminalSettings terminal_group="gcash-h5"/>,
        () => <TerminalSettings terminal_group="card"/>,
    ]

    return <>{
        cards.map((Card, i, _) => 
            <Grid key={i} item xs={12}>
                <Card />
            </Grid>
        )
    }</>
}

export default Terminals;
