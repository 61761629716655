import { KeyboardArrowUp, Menu as MenuIcon, NavigateNext } from "@mui/icons-material";
import { AppBar, Box, Breadcrumbs, Button, Divider, Fab, Grid, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScrollTop from "./ScrollTop";

interface Props {
    children?: any
}

const NavigationHeader = ({children}: Props) => {

    const navigate   = useNavigate();
    const {pathname} = useLocation();
    const type       = pathname.slice(1).split('/')[0]
    const logout = () => {
        sessionStorage.clear();
        window.location.reload();
    }

    const {id} = useParams();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return <>
        <Grid container>
            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <AppBar color="default" position="fixed" sx={{textTransform: 'uppercase', whiteSpace: 'nowrap'}}>
                        <Toolbar>
                            <Breadcrumbs separator={<NavigateNext fontSize="small"/>} sx={{ flexGrow: 1 }}>
                                <Link href='.' underline="none" color="inherit">
                                    <Typography color="inherit" display="inline">
                                        Backend Payment
                                    </Typography>
                                </Link>
                                {
                                    pathname
                                    .replace(`/${type}/${id}`, '')
                                    .split('/')
                                    .slice(1)
                                    .map(path =>
                                        <Link
                                            underline = "hover"
                                            color     = "primary"
                                            sx        = {styles.breadCrumbLink}
                                            onClick   = {() => navigate(path)}
                                        >
                                            {path}
                                        </Link>
                                    )
                                }
                            </Breadcrumbs>
                            <Grid item sx={{display: {sm: 'flex', xs: 'none'}}}>
                                <>{
                                    'admin' == type && id && <>
                                        <Grid item>
                                            <Button onClick={() => navigate('cash-in')}>Cash-In</Button>
                                        </Grid>
                                        {/* <Grid item>
                                            <Button onClick={() => navigate('cash-out')}>Cash-Out</Button>
                                        </Grid> */}
                                        <Grid item>
                                            <Button onClick={() => navigate('tid-mid')}>TID/MID</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => navigate('accounts')}>Accounts</Button>
                                        </Grid>
                                    </>
                                }</>
                                <Grid item sx={{textAlign: 'right', marginLeft: 'auto'}}>
                                    <Button variant="text" onClick={logout}>
                                        Log out
                                    </Button>
                                </Grid>
                            </Grid>
                            <Box>
                                <IconButton
                                    aria-label = "open drawer"
                                    edge       = "start"
                                    sx         = {{display: { sm: 'none' } }}
                                    onClick    = {event => setAnchorEl(event.currentTarget)}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    anchorEl = {anchorEl}
                                    open     = {!!anchorEl}
                                    onClose  = {() => setAnchorEl(null)}
                                    sx       = {{minWidth: '100px'}}
                                >
                                    <MenuItem onClick={() => navigate('cash-in')}>Cash-In</MenuItem>
                                    {/* <MenuItem onClick={() => navigate('cash-out')}>Cash-Out</MenuItem> */}
                                    <MenuItem onClick={() => navigate('tid-mid')}>TID/MID</MenuItem>
                                    <MenuItem onClick={() => navigate('accounts')}>Accounts</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={logout}>Log out</MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    <Toolbar id="back-to-top-anchor" />
                </Grid>
                <Grid item xs={12} style={{padding: '1rem'}}>
                    {children}
                </Grid>
                <ScrollTop>
                    <Fab size="small" aria-label="scroll back to top">
                        <KeyboardArrowUp />
                    </Fab>
                </ScrollTop>
            </Grid>
        </Grid>
    </>
}

const styles = {
    breadCrumbLink: {
        cursor: 'pointer'
    }
}

export default NavigationHeader;