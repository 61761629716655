import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import { ACCOUNTS_URL, ANALYTICS_URL, BALANCE, DOWNLOAD_TRANSACTIONS, PROVIDERS_URL, SETTLEMENTS_URL, TERMINALS_URL, TOTAL, TRANSACTIONS } from "app/urls";
import { useParams } from "react-router-dom";

const useApi = () => {
    const {id,}           = useParams();
    const {partner, type} = useLoggedInRoute();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${partner?.access_token || ''}`
    }

    const getBarGraphData = (query: {
        start_date: string,
        end_date  : string,
        client?   : string,
        account?  : string
    }) => {
        let params = new URLSearchParams(query);
        return fetch(`${ANALYTICS_URL({id, type})}/graphs/hourly?${params}`, {headers})
            .then(res => res.json())
            .catch(res => ({error: res}))
    }

    const getTotals = (method: string, query: {
        start_date: string
        end_date: string
    }) => {
        let params = new URLSearchParams(query);
        return fetch(`${ANALYTICS_URL({id, type})}/accounts/${method}/totals?${params}`, {headers})
            .then(res => res.json())
            .catch(res => ({error: res}))
    }

    const getTerminals = (payload?: any) => {
        let params = new URLSearchParams(payload).toString();
        return fetch(`${TERMINALS_URL({id, type})}/totals?${params}`, {headers})
            .then(res => res.json())
            .catch(res => ({error: res}))
    }

    const removeTerminalID = (payload?: any) => fetch(TERMINALS_URL({id, type}), {headers, method: 'DELETE', body: JSON.stringify(payload)})

    const getQuery = (data: any, include_last_key = true, last_key?: any, type?: string, intent?: string) => {
        include_last_key && last_key && (data['last_key'] = JSON.stringify(last_key));
        if ('partner' == type?.toLowerCase()) {
            data = {
                ...data,
                tranType: 'C',
                tranDescription: 'P2M',
            }
        }
        if (intent) {
            data['intent'] = intent
        }
        return Object
            .entries(data)
            .filter(([_, value]) => !!value)
            .map(
                ([key, value]) => `${key}=${value}`
            )
            .join('&')
    }

    const getBalance = (params: {
        bank  ?: string
        source?: string
        type  ?: string
        client?: string
        account?: string
    }) => fetch(BALANCE({...params, id}), {headers}).then(res => res.json())

    const getTotal = async ({id, type, bank, source, ...query}: {
        id             ?: string
        bank           ?: string
        type           ?: string
        start          ?: string
        end            ?: string
        source         ?: string
        intent         ?: string
        tranType       ?: string
        tranDescription?: string
        category       ?: string
        client         ?: string
        account        ?: string
    }) => {
        query['tranType']        = query.tranType || 'C';
        query['tranDescription'] = query.tranDescription || 'P2M';
        return fetch(TOTAL({id, type, bank, source, query: getQuery(query)}), {headers})
    }

    const getTransactions = ({id, type, bank, source, ...query}: {
        id             ?: string
        bank           ?: string
        type           ?: string
        start          ?: string
        end            ?: string
        source         ?: string
        intent         ?: string
        status         ?: string
        pending        ?: boolean
        tranType       ?: string
        provider       ?: string
        category       ?: string
        tranDescription?: string
        client         ?: string
        account        ?: string
    }) => {
        query['tranType']        = query.tranType || 'C';
        query['tranDescription'] = query.tranDescription || 'P2M';
        return fetch(TRANSACTIONS({id, type, bank, source, query: getQuery(query)}), {headers})
    }

    const downloadTransactions = ({id, type, bank, source, file_type, ...query}: {
        id             ?: string
        type           ?: string
        bank           ?: string
        source         ?: string
        intent         ?: string
        start          ?: string
        end            ?: string
        tranType       ?: string
        tranDescription?: string
        category       ?: string
        client         ?: string
        account        ?: string
        file_type      ?: 'xlsx' | 'csv'
    }) => {
        query['tranType']        = query.tranType || 'C';
        query['tranDescription'] = query.tranDescription || 'P2M';
        return fetch(DOWNLOAD_TRANSACTIONS({id, type, bank, source, file_type, query: getQuery(query)}), {headers})
    }

    const getTotalTerminalsPerAccount = () => (
        fetch(`${TERMINALS_URL({id, type})}/totals-per-account`, {headers}).then(response => response.json())
    )

    const getTerminalsSummary = (terminal: string) => (
        fetch(`${TERMINALS_URL({id, type})}/${terminal}/summary`, {headers}).then(response => response.json())
    )

    const getTerminalsByType = (terminal: string) => (
        fetch(`${TERMINALS_URL({id, type})}/${terminal}`, {headers}).then(response => response.json())
    )

    const getSettlementsAccounts = (date: string) => (
        fetch(`${SETTLEMENTS_URL({id})}/accounts?date=${date}`, {headers}).then(res => res.json())
    )

    const getProviders = (query: {client: string, account: string}) => (
        fetch(PROVIDERS_URL({id, query: getQuery(query)}), {headers}).then(res => res.json())
    )

    const updateProviderLimit = (limit: string, payload: any) => {
        let url    = `${PROVIDERS_URL({id})}/limits/${limit}`,
            method = 'PUT',
            body   = JSON.stringify(payload);
        return fetch(url, {method, headers, body}).then(res => res.json());
    }

    const updateProviderSplits = (split: string, payload: any) => {
        let url    = `${PROVIDERS_URL({id})}/splits/${split}`,
            method = 'PUT',
            body   = JSON.stringify(payload);
        return fetch(url, {method, headers, body}).then(res => res.json());
    }

    const changeSettlement = (
        payload: {
            date        : string
            source      : string
            total       : number
            account_name: string
        }
    ) => {
        let url    = `${SETTLEMENTS_URL({id})}/change`,
            method = 'PUT',
            body   = JSON.stringify(payload);
        return fetch(url, {method, headers, body})
    }

    const approveSettlement = (
        payload: {
            date           : string
            source         : string
            approver_number: number
            account_name   : string
        }
    ) => {
        let url    = `${SETTLEMENTS_URL({id})}/approve`,
            method = 'POST',
            body   = JSON.stringify(payload);
        return fetch(url, {method, headers, body})
    }

    const callPendingTransactionsApproval = ({method, body}: any) => (
        fetch(`${SETTLEMENTS_URL({id})}/approval/pending`, {method, headers, body}).then(response => response.json())
    )

    const getPendingTransactionApprovalStatus = () => callPendingTransactionsApproval({method: 'GET'});
    const cancelPendingTransactionsApproval   = () => callPendingTransactionsApproval({method: 'DELETE'});
    const processPendingTransactionApproval   = (transactions: string[]) => callPendingTransactionsApproval({method: 'POST', body: JSON.stringify({transactions})});
    const approvePendingTransactions          = (approver_number: 1 | 2) => callPendingTransactionsApproval({method: 'PUT' , body: JSON.stringify({approver_number})});

    const getVolumePerAccount = (payload: {start_date: string, end_date: string}) => (
        fetch(
            `${ANALYTICS_URL({id, type})}/volumes?${getQuery(payload)}`,
            {headers}
        ).then(response => response.json())
    )

    const getAccounts = () => fetch(ACCOUNTS_URL(id || 'undefined'), {headers}).then(response => response.json());

    const updateAccount = ({client, account, status}: {
        client: string,
        account: string,
        status: 'on' | 'off'
    }) => {
        let url = `${ACCOUNTS_URL(id || 'undefined')}/${client}-${account}`,
            method = 'PUT',
            body = JSON.stringify({status});
        return fetch(url, {headers, method, body});
    }

    const updateDisbursement = ({client, account, ...payload}: {
        client   : string,
        account  : string,
        status?  : 'on' | 'off',
        provider?: string
    }) => {
        let url = `${ACCOUNTS_URL(id || 'undefined')}/${client}-${account}/disbursement`,
            method = 'PUT',
            body = JSON.stringify(payload);
        return fetch(url, {headers, method, body});
    }

    const updateSof = ({client, account, sof, ...payload}: {
        client   : string,
        account  : string,
        sof      : 'qr_ph' | 'mastercard_visa' | 'gcash',
        status?  : 'on' | 'off',
        provider?: string,
        weight?  : number
    }) => {
        let url = `${ACCOUNTS_URL(id || 'undefined')}/${client}-${account}/sof/${sof}`,
            method = 'PUT',
            body = JSON.stringify(payload);
        return fetch(url, {headers, method, body});
    }

    return {
        getBarGraphData,
        getTotals,
        getTerminals,
        removeTerminalID,
        getBalance,
        getTotal,
        getTransactions,
        downloadTransactions,
        getTotalTerminalsPerAccount,
        getTerminalsSummary,
        getTerminalsByType,
        getSettlementsAccounts,
        changeSettlement,
        approveSettlement,
        getPendingTransactionApprovalStatus,
        cancelPendingTransactionsApproval,
        processPendingTransactionApproval,
        approvePendingTransactions,
        getProviders,
        updateProviderLimit,
        updateProviderSplits,
        getVolumePerAccount,
        getAccounts,
        updateAccount,
        updateDisbursement,
        updateSof
    }
}

export default useApi;