import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import ApproverButton from "app/components/Account/ApproverButton";
import NumberInput from "app/components/Account/NumberInput";
import { formatDate, statusColors } from "app/components/Account/utils";
import InlineLabeledText from "app/components/InlineLabeledText";
import LoaderButton from "app/components/LoaderButton";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

interface AccountProp {
    date: string
    account: any
}

const Account = ({date, account: initAccount}: AccountProp) => {

    const {changeSettlement} = useApi();

    const [account, setAccount]   = useState(initAccount);
    const [total, setTotal]       = useState(account.amount || account.net || 0);
    const [changing, setChanging] = useState(false);
    const [settled, setSettled]   = useState(account.status && ['processing', 'completed'].includes(account.status))

    const change = async () => {
        if (!total) {
            Swal.fire({
                title: 'Invalid Total',
                icon: 'error'
            })
            return
        }
        setChanging(true)
        await changeSettlement({
            date, total,
            source : account.source,
            account_name : account.name
        }).then(async res => {
            if (200 == res.status) {
                Swal.fire({
                    title: 'Success',
                    text: `Changed total to ${total.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'PHP',
                    })}`,
                    icon: 'success'
                })
                let settlement = await res.json();
                updateAccount({
                    amount      : settlement.amount,
                    last_updated: settlement.last_updated,
                    approvers   : settlement.approvers
                })
            } else {
                Swal.fire({
                    title: `Failed (${res.status})`,
                    text: await res.text(),
                    icon: 'error'
                })
            }
            return
        })
        setChanging(false)
    }

    const updateAccount = (settlement: any) => setAccount({...account, ...settlement})

    useEffect(() => setTotal(account.amount || account.net || 0), [account])
    useEffect(() => setSettled(account.status && ['processing', 'completed'].includes(account.status)), [account])

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="h5">{account.name}</Typography>
                        <Typography gutterBottom variant="subtitle2" fontWeight="normal">
                            <InlineLabeledText label="Settlement Date" value={moment(account.settlement_date).format('MMMM DD, YYYY')} />
                            <InlineLabeledText label="Gross" value={
                                (account.gross || 0).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PHP',
                                })
                            } />
                            <InlineLabeledText label="Fee" value={{
                                percentage: `${account.fees?.value || 0}%`,
                                fixed: (+account.fees?.value || 0).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PHP',
                                })
                            }[(account.fees?.type || '') as string]} />
                            <InlineLabeledText label="Net" value={
                                (account.net || 0).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PHP',
                                })
                            } />
                            <InlineLabeledText label="Source" value={{
                                allbank_p2m: 'AllBank P2M'
                            }[account.source as string] || account.source} />
                        </Typography>
                    </Box>
                    <NumberInput
                        value    = {total}
                        label    = "Total: ₱"
                        disabled = {settled}
                        helper   = {account.last_transaction?.tranDate && `as of ${formatDate(account.last_transaction.tranDate)}`}
                        onChange = {(e: any) => setTotal(+(e.target.value.replaceAll(',', '')))}
                    />
                    <LoaderButton
                        onClick   = {change}
                        loading   = {changing}
                        disabled  = {settled || changing}
                        display   = "inline-block"
                        marginY   = {2}
                    >
                        Change
                    </LoaderButton>
                    {account.last_updated &&
                        <Typography variant="caption" display="inline" style={{margin: '0 1rem'}}>last changed {formatDate(account.last_updated)}</Typography>
                    }
                </CardContent>
                <CardContent>
                    <InlineLabeledText label="Bank" value={account.bank?.name}/>
                    <InlineLabeledText label="Account Name" value={account.bank?.account_name}/>
                    <InlineLabeledText label="Account Number" value={account.bank?.account_number}/>
                    <Box my={3}>
                        <ApproverButton
                            date            = {date}
                            account         = {account}
                            approver_number = {1}
                            updateAccount   = {updateAccount}
                        />
                        <ApproverButton
                            date            = {date}
                            account         = {account}
                            approver_number = {2}
                            updateAccount   = {updateAccount}
                        />
                    </Box>

                    {account.status && <InlineLabeledText
                        label      = "Status"
                        value      = {account.status.toUpperCase()}
                        valueProps = {{color: statusColors[account.status]}}
                    />}
                    {'failed' == account.status && <>
                        <Typography fontWeight="bold">Last Failed Attempt:</Typography>
                        <Typography>{JSON.stringify(account.last_failed_attempt)}</Typography>
                    </>}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Account;