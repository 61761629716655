import { Button, CircularProgress } from "@mui/material";

const LoaderButton = (props: any) => (
    <Button
        fullWidth={props.fullWidth}
        variant  = {props.variant || "contained"}
        sx       = {props.sx || {
            display: props.display || 'block',
            margin: props.margin,
            marginY: props.marginY,
            marginX: props.marginX,
            minWidht: props.minWidth
        }}
        disabled = {props.disabled}
        {...props}
    >
        {
        props.loading?
        <CircularProgress size={20}/>:
        props.children
    }
    </Button>
)

export default LoaderButton;