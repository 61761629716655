import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const RBGIColumns = ({isAdmin}: any) => {
    return (
        <TableRow>
            <TableCell>reference_id</TableCell>
            <TableCell>amount</TableCell>
            <TableCell>postingTime</TableCell>
            <TableCell>InstrId</TableCell>
            <TableCell>MsgId</TableCell>
            <TableCell>OrgnlTxId</TableCell>
            <TableCell>OrgnlEndToEndId</TableCell>
            <TableCell>CreDtTm</TableCell>
            <TableCell>CreDtm</TableCell>
            <TableCell>TtlIntrBkSttlmAmt</TableCell>
            <TableCell>TxSts</TableCell>
            <TableCell>AccptncDtTm</TableCell>
            <TableCell>Rsn</TableCell>
            <TableCell>acctno</TableCell>
            <TableCell>institution</TableCell>
            <TableCell>bic</TableCell>
            <TableCell>transactionFee</TableCell>
            <TableCell>status</TableCell>
        </TableRow>
    )
}

export default RBGIColumns;