import { Grid } from "@mui/material";
import Account from "app/cards/Account";
import AccountsPieGraph from "app/cards/AccountsPieGraph";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import { useEffect, useState } from "react";
import useApi from "./Dashboard/useApi";

const Accounts = () => {

    const {type} = useLoggedInRoute();
    const {getAccounts} = useApi();

    const [accounts, setAccounts] = useState<any[]>([]);

    const loadAccounts = () => {
        getAccounts().then(response => setAccounts(response))
    }

    useEffect(loadAccounts, [])

    const cards = [
        AccountsPieGraph,
        ...accounts.map(account => () => <Account account={account}/>)
    ];

    return <>{
        cards.map((Card, i, _) => 
            Card && <Grid key={i} item xs={12}>
                <Card />
            </Grid>
        )
    }</>
}

export default Accounts;