import { Navigate, Route, Routes as Switch } from "react-router-dom";

import CashIn from "app/pages/CashIn";
import AdminDashboard from "app/pages/Dashboard/AdminDashboard";
import PartnerDashboard from "app/pages/Dashboard/PartnerDashboard";
import Home from "app/pages/Home";
import Login from "app/pages/Login";
import ResetPassword from "app/pages/ResetPassword";
import Terminals from "app/pages/Terminals";
import ProtectedRoute from "./ProtectedRoute";
import Accounts from "./pages/Accounts";
import CashOut from "./pages/CashOut";
import Client from "./pages/Client";

const Routes = () => {

    return <>
        <Switch>
            <Route index element={<Home/>}/>
            <Route path="admin/:id">
                <Route path="login" element={<Login/>}/>
                <Route path="reset-password" element={<ResetPassword/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route element={<AdminDashboard/>}>
                        <Route index element={<Navigate to="cash-in"/>}/>
                        <Route path="cash-in" element={<CashIn/>}/>
                        <Route path="cash-out" element={<CashOut/>}/>
                        <Route path="tid-mid" element={<Terminals/>}/>
                        <Route path="accounts" element={<Accounts/>}/>
                        <Route path=":partner" element={<Client/>}/>
                    </Route>
                </Route>
            </Route>
            <Route path="partner/:id">
                <Route path="login" element={<Login/>}/>
                <Route path="reset-password" element={<ResetPassword/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route index element={<PartnerDashboard/>}/>
                </Route>
            </Route>
        </Switch>
    </>
}

export default Routes;