import { useLocation, useNavigate, useParams } from "react-router-dom";

const useLoggedInRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const type = location.pathname.slice(1).split('/')[0]

    let user: any = sessionStorage.getItem('user');
    if (user) {
        user = JSON.parse(user);
        if (
            !(user as any)?.profile?.groups?.includes(type?.toLowerCase() || '') ||
            (user as any)?.profile?.url != id
        ) {
            sessionStorage.clear();
            window.location.reload();
            navigate(`/${type}/${id}/login`);
        } else {
            return {
                partner: user,
                type   : type as any
            }
        }
    } else {
        sessionStorage.clear();
        navigate(`/${type}/${id}/login`);
    }
    return {}
}

export default useLoggedInRoute;