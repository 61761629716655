import { Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import NumberInput from "app/components/Account/NumberInput";
import InlineLabeledText from "app/components/InlineLabeledText";
import LoaderButton from "app/components/LoaderButton";
import useApi from "app/pages/Dashboard/useApi";
import { amountFormat } from "app/utils";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./styles";

type SectionType = 'qr_ph' | 'mastercard_visa'
const sections = {
    qr_ph       : 'QRPH',
    // gcash_h5    : 'GCash-H5',
    mastercard_visa: 'Credit/Debit Cards'
}
type ProviderType = 'rbgi' | 'allbank' | 'xendit' | 'mcc'
const providers = {
    rbgi   : 'RBGI',
    allbank: 'AllBank',
    brankas: 'Brankas',
    xendit : 'Xendit',
    mcc    : 'MCC',
    icash  : 'ICash',
}

const Providers = ({client, account}: any) => {

    const {
        getProviders,
        updateProviderLimit,
        updateProviderSplits,
    } = useApi();

    const [providers, setProviders] = useState<any>();
    const [loading, setLoading]     = useState(true);

    const load = () => {
        setLoading(true)
        getProviders({client, account})
            .then(data => setProviders(data))
            .finally(() => setLoading(false))
    }

    useEffect(load, [])

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" sx={{mb: '2rem'}}>
                    <Grid item>
                        <Typography display="inline" variant="h6" fontWeight="bold">PROVIDERS</Typography>
                    </Grid>
                    <Grid item>
                        <LoaderButton disabled={loading} loading={loading} onClick={load}>
                            Refresh
                        </LoaderButton>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    {Object.keys(sections).map(
                        provider => <>
                            <ProviderSection
                                client       = {client}
                                account      = {account}
                                name         = {provider}
                                limit        = {providers?.limits?.[provider]}
                                splits       = {providers?.default_sof?.[provider]}
                                last_updated = {providers?.last_updated?.[provider]}
                                loading      = {loading}
                            />
                            <Divider orientation="vertical" variant="middle" flexItem sx={{mr:"-1px"}}/>
                        </>
                    )
                }
                </Grid>
            </CardContent>
        </Card>
    )
}

const ProviderSection = ({client, account, name, limit, splits, last_updated, loading}: any) => {

    const [updating, setUpdating] = useState(false);

    const [value, setValue] = useState(limit);
    const [default_sof, setDefaultSof] = useState(splits);

    const {
        updateProviderLimit,
        updateProviderSplits,
    } = useApi();

    const updateLimit = () => {
        setUpdating(true)
        updateProviderLimit(name, {
            client, account, value
        }).finally(() => setUpdating(false))
    }

    const updateSplits = (value: number, index: number) => {
        let splits = [...default_sof];
        splits[index].weight = value;
        setDefaultSof(splits);
    }

    const updateTransactionSplits = () => {
        setUpdating(true)
        updateProviderSplits(name, {
            client, account, splits: default_sof
        }).finally(() => setUpdating(false))
    }

    useEffect(() => setValue(+limit), [limit])
    useEffect(() => setDefaultSof(splits), [splits])

    return <>
        <Grid item md={4} px={4}>
            <Typography variant="h6" mt="-10px">{sections[name as SectionType]}</Typography>
            <Typography gutterBottom variant="subtitle2" fontWeight="normal" color="GrayText">Last Updated: {
                last_updated?
                moment(last_updated).format('MMMM DD, YYYY hh:mm:ss'):
                'None'
            }</Typography>
            <Grid container spacing={2} alignItems="center" my="1.5rem">
                <Grid item xs={12} sm={9} md={12} lg={8}>
                    <NumberInput label="Limit: ₱" value={value} onChange={(e: any) => setValue(+(e.target.value as string).replaceAll(',', ''))}/>
                </Grid>
                <Grid item xs={12} sm={3} md={12} lg={4}>
                    <LoaderButton fullWidth disabled={loading || updating} loading={updating} onClick={updateLimit}>
                        Change
                    </LoaderButton>
                </Grid>
            </Grid>
            <Box my="1.5rem">
                <Typography variant="h6" display="inline-block" gutterBottom>Transaction Split</Typography>
                <LoaderButton display="inline-block" marginX="1rem" disabled={loading || updating} loading={updating} onClick={updateTransactionSplits}>
                    Change
                </LoaderButton>
            </Box>
            <Grid container spacing={1} sx={{whiteSpace: 'nowrap'}} alignItems="center">
                <Grid item xs={12}>
                    <InlineLabeledText my={0} label="Total" value={amountFormat(default_sof?.reduce((sum: number, provider: any) => (provider?.total || 0) + sum,0))}/>
                    {default_sof && <Typography gutterBottom
                        variant    = "subtitle2"
                        fontWeight = "normal"
                        fontSize   = "12px"
                        color      = "GrayText"
                    >
                        as of {default_sof && moment.max(default_sof
                            .filter((provider: any) => !!provider.last_transaction)
                            .map((provider: any) => provider.last_transaction)
                        )}
                    </Typography>}
                </Grid>
                {default_sof?.map(
                    (provider: any, i: number) => <>
                        <Grid item xs={9} md={12} lg={9}>
                            <InlineLabeledText my={0} label={provider.name && providers[provider.name as ProviderType] || provider?.name} value={amountFormat(provider?.total)}/>
                            <Typography gutterBottom
                                variant    = "subtitle2"
                                fontWeight = "normal"
                                fontSize   = "12px"
                                color      = "GrayText"
                            >
                                last transaction: {provider.last_transaction? moment(provider.last_transaction).format('MMMM DD, YYYY hh:mm:ss'): 'None'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={12} lg={3}>
                            <NumberInput value={provider?.weight} onChange={(e: any) => updateSplits(+(e.target.value.replaceAll(',', '')), i)}/>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    </>
}

export default Providers;