import { Button, Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import styles from "app/cards/styles";
import LoaderButton from "app/components/LoaderButton";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useSorter from "app/hooks/useSorter";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const columns = Object.entries({
    name   : "Terminal ID",
    accounts: "Clients",
    total  : "Amount",
    last_transaction: "Last Transaction",
    remove: ""
})

const TerminalTable = ({client, account}: any) => {

    const {partner} = useLoggedInRoute();

    const {
        getTerminals: getTerminalIDs,
        removeTerminalID
    } = useApi();

    const sorter = useSorter();
    const isClient = !!client && !!account

    const [data, setData]           = useState<any[]>([]);
    const [terminals, setTerminals] = useState<any[]>([]);
    const [loading, setLoading]     = useState(false);
    const [total, setTotal]         = useState(0);
    const [search, setSearch]       = useState('');
    const [start, setStart]         = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd]             = useState(moment().format('YYYY-MM-DD'));
    const [sortBy, setSortBy]       = useState('total');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const getTerminals = () => {
        setLoading(true);
        let payload: any = {
            start_date: start,
            end_date: end
        }
        if (client && account) {
            payload = {...payload, client, account}
        }
        getTerminalIDs(payload)
            .then(res => !res.error && res && setData(res))
            .finally(() => setLoading(false));
    }

    const sort = (name: string) => {
        if (name == sortBy) {
            setSortOrder(sortOrder == 'desc'? 'asc': 'desc')
        } else {
            setSortBy(name)
            setSortOrder('desc')
        }
    }

    const sortTerminals = () => {
        data && setTerminals(
            [...data.sort(sorter({
                key: sortBy,
                order: sortOrder
            }))]
        )
    }

    const computeTotal = () => {
        setTotal(data?.reduce((sum: number, current: any) => sum + (current?.total || 0), 0) || 0)
    }

    const remove = (name: string) => () => {
        setLoading(true);
        removeTerminalID({name, client, account}).finally(() => {
            setData(data.filter(terminal => name != terminal.name))
            setLoading(false)
        })
    } 

    useEffect(() => partner?.access_token && getTerminals(), [partner?.access_token]);
    useEffect(sortTerminals, [sortBy, sortOrder, data]);
    useEffect(computeTotal, [data]);

    return <>
        <Card sx={styles.card}>
            <CardContent>
                <Grid container
                    spacing        = {2}
                    justifyContent = "space-between"
                    alignItems     = "center"
                >
                    <Grid item marginBottom={4}>
                        <Typography variant="h6" fontWeight="bold" sx={styles.tableTitle}>Terminal IDs / MIDs</Typography>
                    </Grid>
                    <Grid item marginBottom={4}>
                        <Grid container
                            spacing        = {1}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item xs={12} md="auto">
                                <TextField fullWidth
                                    id              = "searchField"
                                    label           = "Search"
                                    type            = "text"
                                    value           = {search}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setSearch(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <TextField fullWidth
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start}
                                    inputProps      = {{max: end}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <TextField fullWidth
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md="auto" alignContent="center">
                                <LoaderButton fullWidth
                                    minWidth = '100px'
                                    loading  = {loading}
                                    onClick  = {() => partner?.access_token && getTerminals()}
                                    disabled = {!!loading}
                                >
                                    Apply
                                </LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map(([key, name]) => ((
                                    (!['accounts', 'remove'].includes(key)) ||
                                    ('accounts' == key && !isClient) ||
                                    ('remove' == key && isClient)
                                ) &&
                                    <TableCell sortDirection={sortBy === key? sortOrder : undefined}>
                                        <TableSortLabel
                                            active    = {sortBy === key}
                                            direction = {sortBy === key? sortOrder : 'asc'}
                                            onClick   = {() => sort(key)}
                                        >
                                            {name}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {terminals?.map((terminal: any) =>
                                <TableRow hover>
                                    <TableCell>{terminal.name}</TableCell>
                                    {!isClient && <TableCell>
                                        {terminal.accounts? terminal.accounts.map((account: string) => <>{account}<br/></>): <></>}
                                    </TableCell>}
                                    <TableCell>{amountFormat(terminal.total)}</TableCell>
                                    <TableCell>{terminal.last_transaction?.tranDate || '-'}</TableCell>
                                    {isClient && <TableCell>
                                        <LoaderButton variant="text" onClick={remove(terminal.name)} loading={loading} disabled={!!loading}>
                                            Remove
                                        </LoaderButton>
                                    </TableCell>}
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter sx={styles.tableFooter}>
                            <TableRow>
                                {!isClient && <TableCell></TableCell>}
                                <TableCell>
                                    <Typography fontWeight="bold" textAlign="right">TOTAL:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight="bold">{amountFormat(total)}</Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                {isClient && <TableCell></TableCell>}
                                </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {!isClient && <Button variant="contained" href="./tid-mid">
                    Manage
                </Button>}
            </CardContent>
        </Card>
    </>
}

const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {style: 'currency', currency: 'PHP'})

export default TerminalTable;