import { Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import styles from "app/cards/styles";
import Account from "app/components/Account";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const Settlements = () => {

    const {partner} = useLoggedInRoute();
    const {getSettlementsAccounts} = useApi();

    const [loading, setLoading]   = useState(true);
    const [date, setDate]         = useState(moment().format('YYYY-MM-DD'));
    const [accounts, setAccounts] = useState<any[]>([]);

    const getAccounts = () => {
        setLoading(true)
        getSettlementsAccounts(date)
            .then(response => setAccounts(response))
            .finally(() => setLoading(false))
    }

    useEffect(() => partner?.access_token && getAccounts(), [partner?.access_token])

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography display="inline" variant="h5" fontWeight="bold">Settlements</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container
                            spacing    = {2}
                            alignItems = "center"
                        >
                            <Grid item>
                                <TextField
                                    variant    = "outlined"
                                    size       = "small"
                                    type       = "date"
                                    value      = {date}
                                    inputProps = {{max: moment().format('YYYY-MM-DD')}}
                                    onChange   = {e => setDate(e.target.value)}
                            /></Grid>
                            <Grid item>
                                <Button
                                    variant = "contained"
                                    onClick = {getAccounts}
                                >
                                    Apply

                            </Button></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" padding="1rem 0" spacing={3}>
                    {
                        loading? <Grid item><CircularProgress /></Grid>:
                        accounts.map(account => <Account date={date} account={account}/>)
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Settlements;