import { FormControlLabel, FormGroup, FormHelperText } from "@mui/material";
import Input from "app/components/Account/Input";
import { NumberFormatBase, NumberFormatBaseProps, useNumericFormat } from "react-number-format";

const NumberInput = ({value, onChange, label, helper, disabled}: any) => {
    const numericFormat: NumberFormatBaseProps = useNumericFormat({
        thousandSeparator: ',',
        decimalSeparator : '.',
    })
    return (
        <FormGroup sx={{width: '100%'}}>
            <FormControlLabel
                control = {
                    <NumberFormatBase
                        {...numericFormat}
                        value       = {value}
                        disabled    = {disabled}
                        customInput = {Input}
                        onChange={onChange}
                        sx={{width: '100%'}}
                    />
                }
                label           = {label}
                labelPlacement  = "start"
                componentsProps = {{typography: {fontWeight: "bold"}}}
                style           = {{alignSelf: 'start', margin: 0, whiteSpace: 'nowrap', width: '100%'}}
                
            />
            {helper && <FormHelperText color="GrayText">{helper}</FormHelperText>}
        </FormGroup>
    )
}

export default NumberInput;