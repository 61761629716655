import { useParams } from "react-router-dom";
import useLoggedInRoute from "./useLoggedInRoute";

const usePartner = () => {
    const {partner: user}   = useLoggedInRoute();
    const {partner}         = useParams();
    const path              = partner?.split('-');
    const {client, account} = (
        path && path.length > 1?
        {client: path[0], account: path[1]}:
        {
            client: user?.profile?.client,
            account: user?.profile?.account
        }
    )

    return {client, account}
}

export default usePartner;