import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import { mangoFusionPalette } from '@mui/x-charts/colorPalettes';
import styles from "app/cards/styles";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import { useEffect, useState } from "react";

const TerminalGraph = () => {

    const {partner} = useLoggedInRoute();
    const {
        getTotalTerminalsPerAccount,
        getTerminalsSummary
    } = useApi();

    const [loading, setLoading] = useState(false);

    const [chartData, setChartData]     = useState<any[]>([]);
    const [qrph, setQRPH]               = useState<any>({})
    const [gcashH5, setGcashH5]         = useState<any>({})
    const [cardDetails, setCardDetails] = useState<any>({})

    const getChartData = () => (
        getTotalTerminalsPerAccount().then(response => {
            let total   = Object.values(response).reduce((sum, value) => (sum as number) + (value as number), 0) as number,
                minimum = total*25/9/100, // 10 degrees,  Computation: 10/360 = 25/9 = 2.777...% of total
                others  = 0;
            let chartData = (
                Object.keys(response).map(
                    account => {
                        if (response[account] > minimum) {
                            return {
                                id   : account.replace('-', '_'),
                                label: account,
                                value: response[account]
                            }
                        }
                        others += response[account]
                    }
                )
            )
            chartData.push({
                id   : 'others',
                label: 'Others',
                value: others
            })
            setChartData(chartData);
        })
    )
    
    const getSummary = (type: string, setter: (value: any) => void) => getTerminalsSummary(type).then(response => setter(response))

    const load = () => {
        partner?.access_token && (async () => {
            setLoading(true);
            await getChartData();
            await getSummary('qrph', setQRPH);
            await getSummary('gcash-h5', setGcashH5);
            await getSummary('card', setCardDetails);
            setLoading(false)
        })();
    }

    useEffect(load, [partner?.access_token])

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container>
                    <Grid item marginLeft="auto">
                        <Button
                            variant  = "contained"
                            onClick  = {load}
                            disabled = {loading}
                        >
                            {loading? <CircularProgress size={25}/>: 'Refresh'}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6} md={3} sx={styles.divider}>
                        <TerminalPieChart data={chartData}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={styles.divider}>
                        <Grid height="100%" paddingX="1rem" paddingY="3rem">
                            <Typography variant="h6" marginBottom="2rem">QRPH</Typography>
                            <Typography>Total TIDs:           <Typography color = "GrayText" display = "inline">{qrph.total}</Typography></Typography>
                            <Typography>Active TIDs:          <Typography color = "GrayText" display = "inline">{qrph.active}</Typography></Typography>
                            <Typography>Unassigned TIDs:      <Typography color = "GrayText" display = "inline">{qrph.unassigned}</Typography></Typography>
                            <Typography>Blocked TIDs (GCash): <Typography color = "GrayText" display = "inline">{qrph.gcash_blocked}</Typography></Typography>
                            <Typography>Blocked TIDs (All):   <Typography color = "GrayText" display = "inline">{qrph.blocked}</Typography></Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={styles.divider}>
                        <Grid height="100%" paddingX="1rem" paddingY="3rem">
                            <Typography variant="h6" marginBottom="2rem">GCash-H5</Typography>
                            {Object.keys(gcashH5).map(
                                terminal => (
                                    <Typography>
                                        {terminal}: {(
                                            !!gcashH5[terminal]?.accounts?.length?
                                            <Typography display="inline" color="primary">
                                                active
                                            </Typography>:
                                            <Typography display="inline" color="GrayText">
                                                not in use
                                            </Typography>
                                        )}
                                    </Typography>
                                )
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={styles.divider}>
                        <Grid height="100%" paddingX="1rem" paddingY="3rem">
                            <Typography variant="h6" marginBottom="2rem">Credit/Debit Card</Typography>
                            {Object.keys(cardDetails).map(
                                terminal => (
                                    <Typography>
                                        {terminal}: {(
                                            !!gcashH5[terminal]?.accounts?.length?
                                            <Typography display="inline" color="primary">
                                                active
                                            </Typography>:
                                            <Typography display="inline" color="GrayText">
                                                not in use
                                            </Typography>
                                        )}
                                    </Typography>
                                )
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const TerminalPieChart = ({data}: {data: any[]}) => (
    <PieChart
        margin={{ top: 0, bottom: 200, left: 30, right: 30 }}
        colors={mangoFusionPalette}
        series={[{
            data,
            arcLabel: (item) => item.value,
            arcLabelMinAngle: 25,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: {additionalRadius: -30},
        }]}
        slotProps={{
            legend: {
                itemMarkWidth: 8,
                itemMarkHeight: 8,
                markGap: 5,
                itemGap: 20,
                labelStyle: {fontSize: 14},
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 10,
                
            }
        }}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
            }
        }}    
    />
)

export default TerminalGraph;