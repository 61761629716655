import { Box, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import styles from "app/cards/styles";
import LoaderButton from "app/components/LoaderButton";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const BarGraph = ({client, account}: any) => {

    const {partner} = useLoggedInRoute();
    const {getBarGraphData} = useApi();

    const [query, setQuery] = useState<any>({
        ...(client && account && {client, account} || {}),
        start_date: moment().format('YYYY-MM-DD'),
        end_date  : moment().format('YYYY-MM-DD')
    })
    const [startDate, setStartDate] = useState(query.start_date);
    const [endDate, setEndDate]     = useState(query.end_date);

    const [totals, setTotals] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const [cash_in, setCashIn]   = useState({amount: 0, count: 0});
    const [cash_out, setCashOut] = useState({amount: 0, count: 0});
    const [fees, setFees]        = useState({amount: 0, count: 0});
    const [revenue, setRevenue]  = useState({amount: 0, count: 0});

    const refresh = () => {
        setLoading(true)
        getBarGraphData(query)
            .then(totals => setTotals(totals))
            .finally(() => setLoading(false));
    }

    const computeTotals = () => {
        let cash_in  = {amount: 0, count: 0},
            cash_out = {amount: 0, count: 0},
            fees     = {amount: 0, count: 0},
            revenue  = {amount: 0, count: 0};

        for (const total of Object.values(totals||{})) {
            let obj = total as any;
            if (obj.cash_in) {
                cash_in.amount += +obj.cash_in || 0;
                cash_in.count  += 1;
            }
            if (obj.cash_out) {
                cash_out.amount += +obj.cash_out || 0;
                cash_out.count  += 1;
            }
            if (obj.fees) {
                fees.amount += +obj.fees || 0;
                fees.count  += 1;
            }
            if (obj.net) {
                revenue.amount += +obj.net || 0;
                revenue.count  += 1;
            }
        }
        setCashIn(cash_in);
        setCashOut(cash_out);
        setFees(fees);
        setRevenue(revenue);
    }

    useEffect(() => client && account && setQuery({...query, client, account}), [client, account])
    useEffect(() => partner?.access_token && refresh(), [query, partner?.access_token])
    useEffect(computeTotals, [totals])

    return (
        <Card style={styles.card}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Grid item md="auto">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md="auto">
                                <TotalGroup
                                    label    = "Cash-in"
                                    subtitle = {cash_in.count.toLocaleString('en-US', {maximumFractionDigits: 0})}
                                    total    = {cash_in.amount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md="auto">
                                <TotalGroup
                                    label    = "Cash-out"
                                    subtitle = {cash_out.count.toLocaleString('en-US', {maximumFractionDigits: 0})}
                                    total    = {cash_out.amount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md="auto">
                                <TotalGroup
                                    label    = "Revenue"
                                    subtitle = {revenue.count.toLocaleString('en-US', {maximumFractionDigits: 0})}
                                    total    = {revenue.amount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md="auto">
                                <TotalGroup
                                    label    = "Fees"
                                    subtitle = {fees.count.toLocaleString('en-US', {maximumFractionDigits: 0})}
                                    total    = {fees.amount}
                                />
                            </Grid>
                            <Grid item md="auto" alignContent="center">
                                <LoaderButton fullWidth
                                    minWidth = "100px"
                                    loading  = {loading}
                                    disabled = {loading}
                                    onClick  = {() => partner?.access_token && refresh()}
                                >
                                    Refresh
                                </LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md="auto">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm="auto" md={12} lg>
                                <StartDate
                                    value      = {startDate}
                                    inputProps = {{max: endDate}}
                                    onChange   = {(e: any) => setStartDate(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto" md={12} lg>
                                <EndDate 
                                    value      = {endDate}
                                    inputProps = {{max: moment().format('YYYY-MM-DD')}}
                                    onChange   = {(e: any) => setEndDate(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto" md={12} lg alignContent="center">
                                <LoaderButton fullWidth loading={loading} disabled={loading} onClick={() => setQuery({...query, start_date: startDate, end_date: endDate})}>Apply</LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" alignContent="center">
                    <BarChart
                        sx     = {{padding: '0.5rem'}}
                        height = {300}
                        series = {[
                            { label: 'Cash-In', color: '#6dc6f0', data: Object.values(totals).map(i => (i as any).cash_in || 0), },
                            { label: 'Cash-Out', color: "#1977bd", data: Object.values(totals).map(i => (i as any).cash_out || 0), },
                        ]}
                        xAxis     = {[{data: Object.keys(totals), scaleType: 'band'}]}
                        slotProps = {{ legend: { hidden: true } }}
                    />
                </Grid>
            </CardContent>
        </Card>
    )
}

const TotalGroup = ({label, subtitle, total}: any) => {
    return <>
        <Box sx={{whiteSpace: 'noWrap'}}>
            <Typography display="inline" variant="h6" fontWeight="normal">{label}</Typography>{' '}
            {subtitle && <Typography display="inline" variant="subtitle1">({subtitle})</Typography>}
        </Box>
        <Typography variant="h6" fontWeight="normal">
            {total?.toLocaleString(undefined, {
                style: 'currency',
                currency: 'PHP'
            })}
        </Typography>
    </>
}

const DateField = (props: any) => {
    return <TextField fullWidth variant="outlined" type="date" InputLabelProps={{shrink: true}} {...props}/>
}
const StartDate   = (props: any) => <DateField label="Start Date" {...props}/>
const EndDate     = (props: any) => <DateField label="End Date" {...props}/>

export default BarGraph;