import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const AllBankColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Reference Number</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Paid At</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ref No.</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Reference Number</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Paid At</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ref No.</TableCell>
        </TableRow>
}

export default AllBankColumns;