import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const GCashRow = ({item, isAdmin, amountFormat}: any) => {
    const fees = (
        'fixed'      == item.fees.type ? item.fees.value:
        'percentage' == item.fees.type ? +item.amount*item.fees.value/100:
        item.fees.value
    )
    const net = +item.amount-fees
    return (
        isAdmin?
            <TableRow hover>
                <TableCell>{`${item.client || ''} - ${item.account || ''}`}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.transaction_id}</TableCell>
                <TableCell>{item.reference_number}</TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>{item.paid_at}</TableCell>
                <TableCell>{amountFormat(item.amount)}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.webhook.refno || item.webhook.reference_number}</TableCell>
            </TableRow>:
            <TableRow hover>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.transaction_id}</TableCell>
                <TableCell>{item.reference_number}</TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>{item.paid_at}</TableCell>
                <TableCell>{amountFormat(item.amount)}</TableCell>
                <TableCell>{amountFormat(net)}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.webhook.refno || item.webhook.reference_number}</TableCell>
            </TableRow>
    )
}

export default GCashRow;