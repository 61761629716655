import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const AllBankColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>Client</TableCell>
            {/* <TableCell>terminalID</TableCell> */}
            <TableCell>TID</TableCell>
            <TableCell>Terminal Name</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Bank Reference</TableCell>
            <TableCell>Payment Channel</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Running Balance</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Reference</TableCell>
            {/* <TableCell>Sender Reference ID</TableCell> */}
            <TableCell>Description</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            {/* <TableCell>TID</TableCell> */}
            <TableCell>Bank Reference</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Transaction Description</TableCell>
            <TableCell>Transaction Code</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>
}

export default AllBankColumns;