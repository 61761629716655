import { Grid } from "@mui/material";
import NavigationHeader from "app/components/NavigationHeader";
import Client from "app/pages/Client";

const PartnerDashboard = () => {
    return (
        <NavigationHeader>
            <Grid container spacing={3}>
                <Client/>
            </Grid>
        </NavigationHeader>
    )
}

export default PartnerDashboard;