import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const CreditDebitColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>Payment ID</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Gross</TableCell>
            <TableCell>Net</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Gross</TableCell>
            <TableCell>Net</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>
}

export default CreditDebitColumns;