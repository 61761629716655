import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { PieChart, mangoFusionPalette, pieArcLabelClasses } from "@mui/x-charts";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import { amountFormat } from "app/utils";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./styles";

const AccountsPieGraph = () => {

    const {partner} = useLoggedInRoute();
    const {getVolumePerAccount} = useApi();

    const [data, setData]        = useState<any>();
    const [start_date, setStart] = useState(moment().format('YYYY-MM-DD'));
    const [end_date, setEnd]     = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading]  = useState(false);

    const apply = () => {
        setLoading(true);
        getVolumePerAccount({start_date, end_date})
            .then(response => setData(response))
            .finally(() => setLoading(false))
    }

    useEffect(apply, [])

    return <>
        <Card sx={styles.card}>
            <CardContent>
                <Grid container
                    spacing      = {2}
                    alignContent = "center"
                    alignItems   = "center"
                >
                    <Grid item sm={4}>
                        <Typography variant="h6" fontWeight="bold" sx={styles.tableTitle}>Volume Per Account</Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <Grid container
                            spacing        = {2}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item xs={12} sm="auto">
                                <TextField fullWidth
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start_date}
                                    inputProps      = {{max: end_date}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <TextField fullWidth
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end_date}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Button fullWidth
                                    variant  = "contained"
                                    color    = "primary"
                                    size     = "large"
                                    onClick  = {() => partner?.access_token && apply()}
                                    disabled = {!!loading}
                                >
                                    {loading? <CircularProgress size="20px" />: 'APPLY'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{whiteSpace: 'nowrap'}} spacing={2}>
                    {data?.cash_in && <>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box height={300+25*(
                                Object
                                    .values(data.cash_in)
                                    .filter((value) => !!value)
                                    .length
                            )}>
                                <PieGraph
                                    data = {
                                        Object
                                            .entries(data.cash_in)
                                            .filter(([_, value]) => !!value)
                                            .map(([label, value]: any) => ({label, value}))
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Grid height="100%" paddingX="1rem" paddingY="3rem">
                                <Typography variant="h6" marginBottom="2rem">Cash-In Transactions</Typography>
                                {Object.entries(data.cash_in).map(([label, value]: any) => (
                                    !!value && <Typography>{label}: <Typography color = "GrayText" display = "inline">{amountFormat(value)}</Typography></Typography>
                                ))}
                            </Grid>
                        </Grid>
                    </>}
                    {data?.cash_out && <>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box height={300+25*(
                                Object
                                    .values(data.cash_out)
                                    .filter((value) => !!value)
                                    .length
                            )}>
                                <PieGraph
                                    data = {
                                        Object
                                            .entries(data.cash_out)
                                            .filter(item => !!item[1])
                                            .map(([label, value]: any) => ({label, value}))
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Grid height="100%" paddingX="1rem" paddingY="3rem">
                                <Typography variant="h6" marginBottom="2rem">Cash-Out Transactions</Typography>
                                {Object.entries(data.cash_out).map(([label, value]: any) => (
                                    !!value && <Typography>{label}: <Typography color = "GrayText" display = "inline">{amountFormat(value)}</Typography></Typography>
                                ))}
                            </Grid>
                        </Grid>
                    </>}
                </Grid>
            </CardContent>
        </Card>
    </>
}

const PieGraph = ({data}: any) => <>
    <PieChart
        margin={{top: 0, bottom: -30+data.length*26.2, left: 30, right: 30}}
        colors={mangoFusionPalette}
        series={[{
            data,
            arcLabelMinAngle: 90,
            arcLabel: (item) => item.value?.toLocaleString('en-US', {maximumFractionDigits: 0}),
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: {additionalRadius: -30},
        }]}
        slotProps={{
            legend: {
                itemMarkWidth: 8,
                itemMarkHeight: 8,
                markGap: 5,
                itemGap: 10,
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
            }
        }}
        sx={{
            position: 'absolute',
            bottom: 0,
            top: 0,
            right: 0,
            left: 0,
            [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontSize: '14px'
            }
        }}
    />
</>

export default AccountsPieGraph;