import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const ICashColumns = ({isAdmin}: any) => {
    return (
        <TableRow>
            <TableCell>Virtual Account No.</TableCell>
            <TableCell>Transaction Reference No.</TableCell>
            <TableCell>Transaction Status</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
        </TableRow>
    )
}

export default ICashColumns;