import { Box, Card, CardContent, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material";
import LoaderButton from "app/components/LoaderButton";
import useApi from "app/pages/Dashboard/useApi";
import { useState } from "react";
import Swal from "sweetalert2";
import styles from "./styles";

const Account = ({account}: any) => {

    const [provider, setProvider] = useState(account.cash_out?.api?.provider || '')

    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [updatingDisbursement, setUpdatingDisbursement] = useState(false);

    const [status, setStatus] = useState(account.status);
    const [disbursementStatus, setDisbursementStatus] = useState(account.cash_out.status);

    const {
        updateAccount,
        updateDisbursement,
    } = useApi();

    const updateStatus = () => {
        let newStatus: 'on' | 'off' = status == 'on'? 'off': 'on';
        setUpdatingStatus(true);
        updateAccount({
            status : newStatus,
            client : account.client,
            account: account.account
        }).finally(() => {
            setStatus(newStatus);
            setUpdatingStatus(false);
        })
    }

    const updateDisbursementStatus = () => {
        let newStatus: 'on' | 'off' = disbursementStatus == 'on'? 'off': 'on';
        setUpdatingDisbursement(true);
        updateDisbursement({
            status : newStatus,
            client : account.client,
            account: account.account
        }).finally(() => {
            setDisbursementStatus(newStatus);
            setUpdatingDisbursement(false);
        })
    }

    const updateDisbursementProvider = () => {
        setUpdatingDisbursement(true);
        updateDisbursement({
            provider,
            client  : account.client,
            account : account.account
        }).finally(() => {
            setUpdatingDisbursement(false);
        })
    }

    const cash_in = Object.fromEntries(
        Object.keys(channels).map(channel => [
            channel,Object.fromEntries(
                account.cash_in?.default_sof?.[channel]?.map(
                    (provider: any) => ([provider?.name, provider])
                ) || []
            )]
        )
    )

    return <>
        <Card sx={styles.card}>
            <CardContent>
                <Grid container
                    marginBottom = {2}
                    alignContent = "center"
                    alignItems   = "center"
                >
                    <Grid item>
                        <Typography variant="h6" fontWeight="bold" sx={styles.tableTitle}>{account.username}</Typography>
                    </Grid>
                    <Grid item>
                        {
                            updatingStatus?
                            <CircularProgress size="25px"/>:
                            <Switch checked={status == 'on'} onClick={updateStatus}/>
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={3} paddingY={2}>
                        <Box my={3}>
                            <Typography>Contact Person: <Typography color = "GrayText" display = "inline">{account.contact?.name}</Typography></Typography>
                            <Typography>Position: <Typography color = "GrayText" display = "inline">{account.contact?.position}</Typography></Typography>
                            <Typography>Number: <Typography color = "GrayText" display = "inline">{account.contact?.mobile_number}</Typography></Typography>
                            <Typography>Email: <Typography color = "GrayText" display = "inline">{account.contact?.email}</Typography></Typography>
                            <br/>
                            <Typography>Bank: <Typography color = "GrayText" display = "inline">{account.bank?.name}</Typography></Typography>
                            <Typography>Account Name: <Typography color = "GrayText" display = "inline">{account.bank?.account_name}</Typography></Typography>
                            <Typography>Account Number: <Typography color = "GrayText" display = "inline">{account.bank?.account_number}</Typography></Typography>
                        </Box>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography fontWeight="bold" whiteSpace="nowrap">Disbursement</Typography>
                            </Grid>
                            <Grid item>
                                {
                                    updatingDisbursement?
                                    <CircularProgress size="25px"/>:
                                    <Switch checked={disbursementStatus == 'on'} onClick={updateDisbursementStatus}/>
                                }
                            </Grid>
                        </Grid>
                        <FormControl sx={{display: 'block'}}>
                            <FormLabel sx={{fontWeight: 'bold'}}>API Provider:</FormLabel>
                            <RadioGroup name="cashout-provider-group" sx={{paddingX: '1rem'}} onChange={(e) => setProvider(e.target.value)}>
                                <FormControlLabel value="allbank" checked={'allbank' == provider} control={<Radio />} label="AllBank"/>
                                <FormControlLabel value="brankas" checked={'brankas' == provider} control={<Radio />} label="Brankas"/>
                                <FormControlLabel value="xendit" checked={'xendit' == provider} control={<Radio />} label="Xendit"/>
                            </RadioGroup>
                        </FormControl>
                        <LoaderButton variant="contained" sx={{marginY: '1rem'}} loading={updatingDisbursement} disabled={updatingDisbursement} onClick={updateDisbursementProvider}>Update</LoaderButton>
                    </Grid>
                    <Grid item sm={9}>
                        <Grid container spacing={2}>
                            {Object.keys(cash_in).map(
                                sof => <CashInSettings client={account.client} account={account.account} sof={sof} status={account.cash_in?.payment_methods?.[sof] || 'off'} providers={cash_in[sof]}/>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>
}

const channels: any = {
    qr_ph: 'QRPH',
    mastercard_visa: 'Credit/Debit Card',
    gcash: 'GCash'
}

const cash_ins: any = {
    qr_ph: {
        allbank: 'AllBank',
        brankas: 'Brankas',
        xendit : 'Xendit',
        rbgi   : 'RBGI',
        icash  : 'ICash'
    },
    mastercard_visa: {
        allbank: 'AllBank',
        brankas: 'Brankas',
        xendit : 'Xendit',
        rbgi   : 'RBGI',
        icash  : 'ICash'
    },
    gcash: {
        allbank: 'AllBank',
        brankas: 'Brankas',
        xendit : 'Xendit',
        rbgi   : 'RBGI',
        icash  : 'ICash'
    }
}
const CashInSettings = ({client, account, sof, status: currentStatus, providers: currentProviders}: any) => {

    const {updateSof} = useApi();
    const params      = {client, account, sof}

    const [updating, setUpdating]   = useState(false);
    const [status, setStatus]       = useState(currentStatus);
    const [providers, setProviders] = useState(currentProviders);

    const updateWeight = (value: any, provider: any) => {
        setProviders({
            ...providers,
            [provider]: {
                ...providers[provider],
                weight: value
            }
        })
    }

    const save = async () => {
        if ([100, 0].includes(Object.values(providers).reduce((sum, provider: any) => (sum + provider.weight), 0) as number)) {
            setUpdating(true)
            for (const provider in cash_ins[sof]) {
                await updateSof({
                    ...params, provider, weight: providers[provider]?.weight || 0
                })
            }
            setUpdating(false)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid',
                text: 'Total % should be equal to 100'
            })
        }
    }

    const toggleStatus = async () => {
        setUpdating(true)
        let newStatus: 'off' | 'on' = status == 'on'? 'off': 'on';
        await updateSof({...params, status: newStatus})
        setStatus(newStatus)
        setUpdating(false)
    }

    return <>
        <Grid item sm={4}>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography fontWeight="bold" whiteSpace="nowrap">{channels[sof]}</Typography>
                </Grid>
                <Grid item>
                {
                    updating?
                    <CircularProgress size={20}/>:
                    <Switch checked={status == 'on'} onClick={toggleStatus}/>
                }
                </Grid>
            </Grid>
            <Box my={2}>
                {Object.keys(cash_ins[sof]).map((provider: any) => <>
                    <TextField
                        type       = "number"
                        size       = "small"
                        disabled   = {updating}
                        label      = {cash_ins[sof][provider]}
                        sx         = {{marginY: '1rem'}}
                        value      = {providers[provider]?.weight || 0}
                        onChange   = {e => updateWeight(+e.target.value, provider)}
                        InputProps = {{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                    />
                </>)}
                <LoaderButton disabled={updating} loading={updating} onClick={save}>Update</LoaderButton>
            </Box>
        </Grid>
    </>
}

export default Account;