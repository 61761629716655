import { Button } from "@mui/material";
import LoginForm, { LoginForm as FormData } from "app/components/Login";
import { LOGIN } from "app/urls";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const type = location.pathname.slice(1).split('/')[0]

    const login = async (form: FormData) => {
        let response = await fetch(LOGIN({id, type}), {
            method : "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(form)
        });
        let body = await response.text().catch(e => e);
        try {
            let partner = JSON.parse(body);
            if (typeof partner === 'object') {
                sessionStorage.setItem('user', JSON.stringify(partner));
                redirect()
            }
        } catch {
            console.log(body)
        }
        return body
    }

    const redirect = () => {
        if (sessionStorage.getItem('user')) {
            navigate('..')
        }
    }

    useEffect(redirect, [sessionStorage]);

    return <div style={styles.loginContainer as any}>
        <LoginForm submit = {login}/>
        <Button
            type = "button"
            variant = "text"
            onClick = {() => navigate('../reset-password')}
        >
            Reset Password
        </Button>
    </div>
}

const styles = {
    loginContainer: {
        textAlign: 'center'
    }
}

export default Login;