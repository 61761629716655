import React from 'react';
import CacheBuster from 'react-cache-buster';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';
import reportWebVitals from './reportWebVitals';

const isProduction = process.env.NODE_ENV === 'production'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <HelmetProvider>
        <React.StrictMode>
            <CacheBuster
                isEnabled      = {isProduction}  //If false, the library is disabled.
                isVerboseMode  = {false}         //If true, the library writes verbose logs to console.
            >
                <App />
            </CacheBuster>
        </React.StrictMode>
    </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
