import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import styles from "app/cards/styles";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const PrefundedWallet = () => {

    const {partner} = useLoggedInRoute();
    const {getBalance} = useApi();

    const [loading, setLoading] = useState(true);
    const [p2m_balance, setP2MBalance] = useState(0);
    const [opc_balance, setOPCBalance] = useState(0);

    const load = () => {
        let params      = {bank: 'allbank', type: 'admin'},
            p2m_loading = true,
            opc_loading = true;

        setLoading(true);
        getBalance({...params, source: 'P2M'})
            .then(res => setP2MBalance(+(res.AvailableBalance || 0)))
            .finally(() => {
                p2m_loading = false;
                if (!opc_loading) {
                    setLoading(false);
                }
            })
        getBalance({...params, source: 'OPC'})
            .then(res => setOPCBalance(+(res.AvailableBalance || 0)))
            .finally(() => {
                opc_loading = false;
                if (!p2m_loading) {
                    setLoading(false);
                }
            })
    }

    useEffect(() => partner?.access_token && load(), [partner?.access_token])

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography display="inline" variant="h5" fontWeight="bold">Pre-funded Wallets</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant  = "contained"
                            onClick  = {load}
                            disabled = {loading}
                        >
                            {loading? <CircularProgress size={25}/>: 'Refresh'}
                        </Button>
                    </Grid>
                </Grid>
                <Box mb={1}>
                    <Typography
                        variant    = "h6"
                        fontWeight = "normal"
                    >
                        Total: {
                        (p2m_balance + opc_balance).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'PHP',
                        })
                    }</Typography>
                    <Typography
                        variant    = "subtitle2"
                        fontWeight = "normal"
                        color      = "GrayText"
                    >
                        as of {moment().format('MMMM DD, YYYY HH:mm:ss')}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography
                        variant    = "h6"
                        fontWeight = "normal"
                    >
                        AllBank OPC: {
                            opc_balance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'PHP',
                            })
                        }
                    </Typography>
                    <Typography
                        variant    = "subtitle2"
                        fontWeight = "normal"
                        color      = "GrayText"
                    >
                        last transaction: {moment().format('MMMM DD, YYYY HH:mm:ss')}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography
                        variant    = "h6"
                        fontWeight = "normal"
                    >
                        AllBank P2M: {
                            p2m_balance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'PHP',
                            })
                        }
                    </Typography>
                    <Typography
                        variant    = "subtitle2"
                        fontWeight = "normal"
                        color      = "GrayText"
                    >
                        last transaction: {moment().format('MMMM DD, YYYY HH:mm:ss')}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default PrefundedWallet;