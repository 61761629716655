import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const CreditDebitRow = ({item, isAdmin, amountFormat, computeAmount}: any) => {
    return (
        isAdmin?
            <TableRow hover>
                <TableCell>{item.payment_id}</TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>{item.updated_at}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.intent?.replace('_', ' ')}</TableCell>
                <TableCell>{item.receiver}</TableCell>
                <TableCell>{item.receiver_details?.name}</TableCell>
                <TableCell>{item.amount_details?.currency}</TableCell>
                <TableCell>{amountFormat(item.amount_details?.gross)}</TableCell>
                <TableCell>{amountFormat(
                    item.amount_details?.gross -
                    (item.amount_details?.fees?.sending?.fee || 0)
                )}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.status?.replace('_', ' ')}</TableCell>
            </TableRow>:
            <TableRow hover>
                <TableCell>{item.payment_id}</TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>{item.updated_at}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.intent?.replace('_', ' ')}</TableCell>
                <TableCell>{item.receiver}</TableCell>
                <TableCell>{item.receiver_details?.name}</TableCell>
                <TableCell>{item.amount_details?.currency}</TableCell>
                <TableCell>{amountFormat(item.amount_details?.gross)}</TableCell>
                <TableCell>{amountFormat(
                    item.amount_details?.gross -
                    (item.amount_details?.fees?.sending?.fee || 0)
                )}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.status?.replace('_', ' ')}</TableCell>
            </TableRow>
    )
}

export default CreditDebitRow;