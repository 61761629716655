const styles = {
    card: {
        minHeight: '250px',
        padding: '1rem 1rem'
    },
    gridContainer: {
        width: '100%',
        padding: '1rem 0'
    },
    tableContainer: {
        margin         : '1rem 0',
        width          : '100%',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0,
        whiteSpace: 'nowrap'
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    tableFooter: {
        position: 'sticky',
        bottom: 0,
        background: 'white'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    },
    downloadButton: {
        margin: '0.5rem'
    },
    divider: {
        minHeight: '300px',
        borderLeft: '2px solid lightgrey',
        padding: '0 1rem'
    },
    title: {
        verticalAlign: 'bottom'
    },
    tablePaper: {
        margin   : '1rem 0',
        maxHeight: 400,
    },
    table: {
        minWidth: '100%'
    }
}

export default styles;