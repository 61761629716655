
const useSorter = () => {
    
    const compare = (key: string, a: any, b: any) => {
        let aValue = a[key],
            bValue = b[key];

        return (
            bValue < aValue? -1:
            bValue > aValue?  1: 0
        )
    }

    const sort = ({key, order}: {
        key: string,
        order: 'asc' | 'desc'
    }) => (a: any, b: any) => (
        order === 'desc'?
        compare(key, a, b):
        -compare(key, a, b)
    )

    const sorter = (config: {
        key: string,
        order: 'asc' | 'desc'
    }) => sort(config)

    return sorter
}

export default useSorter