import { Box, Typography } from "@mui/material";
import { formatDate } from "app/components/Account/utils";
import LoaderButton from "app/components/LoaderButton";
import useApi from "app/pages/Dashboard/useApi";
import { useState } from "react";
import Swal from "sweetalert2";

const ApproverButton = ({date, account, approver_number, updateAccount}: any) => {
    const {approveSettlement} = useApi();

    const {
        source, completed, approvers,
        name: account_name,
    } = account;
    const key = `approver${approver_number}`;

    const [approving, setApproving] = useState(false);

    const approve = async () => {
        setApproving(true)
        await approveSettlement({date, source, approver_number, account_name}).then(async res => {
            if (200 == res.status) {
                Swal.fire({
                    title: 'Success',
                    text: `Approved ${account_name}`,
                    icon: 'success'
                })
                let settlement = await res.json();
                updateAccount({
                    ...settlement,
                    bank    : settlement.bank || account.bank,
                    provider: settlement.bank || account.bank,
                })
            } else {
                let response: any = await res.text();
                try {
                    response = JSON.parse(response)
                    response = response.detail.response.result_message
                } catch {}
                Swal.fire({
                    title: `Failed (${res.status})`,
                    text: response,
                    icon: 'error'
                })
            }
            return
        })
        setApproving(false)
    }

    return <Box mb={1}>
        <LoaderButton
            disabled = {completed || !!approvers?.[key] || approving}
            loading  = {approving}
            onClick  = {approve}
            display  = "inline-block"
        >
            Approver {approver_number}
        </LoaderButton>
        <Typography variant="caption" display="inline" style={{margin: '0 0.5rem'}}>{
            approvers?.[key]?.approved_at &&
            <>approved {formatDate(approvers?.[key]?.approved_at)}</>
        }</Typography>
    </Box>
}

export default ApproverButton;