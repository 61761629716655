import ResetPasswordForm, { ResetPasswordForm as Form } from "app/components/ResetPassword";
import { RESET_PASSWORD } from "app/urls";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const Controller = () => {
    const location = useLocation();
    const {id} = useParams();
    const type = location.pathname.slice(1).split('/')[0]

    const [params, _] = useSearchParams();

    const submit = async (form: Form) => {
        const response = await fetch(`${RESET_PASSWORD({id, type})}/${params.get('token')}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(form)
        })
        return await response.text().catch(() => "Failed to reset password");
    }

    return {submit}
}

const ResetPassword = () => {
    const {submit} = Controller();

    return <>
        <ResetPasswordForm submit={submit}/>
    </>
}

export default ResetPassword;