import { Grid } from "@mui/material";
import NavigationHeader from "app/components/NavigationHeader";
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
    return (
        <NavigationHeader>
            <Grid container spacing={3}>
                {<Outlet/> || ""}
            </Grid>
        </NavigationHeader>
    )
}

export default AdminDashboard;