import { Grid } from "@mui/material";
import PendingTransactionsTable from "app/cards/PendingTransactionsTable";
import PrefundedWallet from "app/cards/PrefundedWallet";
import Settlements from "app/cards/Settlements";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";

const CashOut = () => {

    const {type} = useLoggedInRoute();

    const cards: any[] = [
        () => <PrefundedWallet/>,
        () => <Settlements/>,
        () => <PendingTransactionsTable
            type     = {type}
            bank     = "jpt"
            intent   = "cash_out"
            provider = "allbank"
        />
    ]

    return <>{
        cards.map((Card, i, _) => 
            Card && <Grid key={i} item xs={12}>
                <Card />
            </Grid>
        )
    }</>
}

export default CashOut;