import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const AllBankRow = ({item, isAdmin, amountFormat, computeAmount}: any) => {
    return (
        isAdmin?
            <TableRow hover>
                <TableCell>{`${item.client || ''} - ${item.account || ''}`}</TableCell>
                {/* <TableCell>{item.terminal?.display_name}</TableCell> */}
                <TableCell>{item.terminal?.terminal_id}</TableCell>
                <TableCell>{item.terminal?.display_name}</TableCell>
                <TableCell>{item.tranID}</TableCell>
                <TableCell>{item.bankReference}</TableCell>
                <TableCell>{item.callback?.body?.payment_channel}</TableCell>
                <TableCell>{item.tranDate}</TableCell>
                <TableCell>{amountFormat(item.tranAmount)}</TableCell>
                <TableCell>{amountFormat(item.runningBalance)}</TableCell>
                <TableCell>{item.tranType}</TableCell>
                <TableCell>{item.reference}</TableCell>
                {/* <TableCell>{item.senderRefid}</TableCell> */}
                <TableCell>{item.tranDescription}</TableCell>
                <TableCell>{item.tranCode}</TableCell>
                <TableCell>{item.ibftStatus}</TableCell>
            </TableRow>:
            <TableRow hover>
                <TableCell>{item.tranID}</TableCell>
                {/* <TableCell>{item.terminal?.display_name}</TableCell>*/}
                <TableCell>{item.bankReference}</TableCell>
                <TableCell>{item.tranDate}</TableCell>
                <TableCell>{amountFormat(item.tranAmount)}</TableCell>
                <TableCell>{amountFormat(+item.net || computeAmount(item.tranAmount, 1.5))}</TableCell>
                <TableCell>{item.reference}</TableCell>
                <TableCell>{item.tranDescription}</TableCell>
                <TableCell>{item.tranCode}</TableCell>
                <TableCell>{item.ibftStatus}</TableCell>
            </TableRow>
    )
}

export default AllBankRow;