import { Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import styles from "app/cards/styles";
import LoaderButton from "app/components/LoaderButton";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import { useEffect, useState } from "react";

interface Props {
    terminal_group: 'qrph' | 'gcash-h5' | 'card'
}

export const terminal_groups = {
    qrph: 'QRPH',
    "gcash-h5": 'GCash - H5',
    card: 'Credit/Debit Cards',
}

export const terminal_providers = {
    allbank  : 'AllBank',
    gcash_h5 : 'GCash-H5',
    metrobank: 'Metrobank',
    xendit   : 'Xendit'
}

export type TerminalProvider = 'allbank' | 'gcash_h5' | 'metrobank' | 'xendit'

const TerminalSettings = ({terminal_group}: Props) => {

    const title = terminal_groups[terminal_group];

    const {partner} = useLoggedInRoute();
    const {getTerminalsByType} = useApi();

    const [loading, setLoading] = useState(true);
    const [terminals, setTerminals] = useState<any[]>([]);

    const getTerminals = () => {
        setLoading(true)
        getTerminalsByType(terminal_group).then(
            response => setTerminals(response)
        ).finally(() => setLoading(false))
    }

    useEffect(() => partner?.access_token && getTerminals(), [partner?.access_token])

    return <>
        <Card sx={styles.card}>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" fontWeight="bold" sx={styles.title}>{title} Terminals</Typography>
                    </Grid>
                    <Grid item>
                        <LoaderButton disabled={loading} loading={loading}>Change</LoaderButton>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Provider</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Accounts</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {terminals?.map((terminal: any) =>
                                <TableRow hover>
                                    <TableCell>{terminal_providers[terminal.provider as TerminalProvider] || terminal.provider || '-'}</TableCell>
                                    <TableCell>{terminal.id || '-'}</TableCell>
                                    <TableCell>{terminal.name || '-'}</TableCell>
                                    <TableCell>{
                                        terminal.blocked? 'Blocked':
                                        terminal.banks?.gcash?.blocked? 'Blocked (GCash)':
                                        !terminal.accounts? 'Not in use':
                                        'Active'
                                    }</TableCell>
                                    <TableCell>
                                        {
                                            terminal.accounts.length &&
                                            terminal.accounts.map((account: string) => (
                                                <Typography>{account}</Typography>
                                            )) || '-'
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    </>
}

export default TerminalSettings;